import React from 'react';
import styled from 'styled-components';

import ContentCarousel from './ContentCarousel';

export const SectionWrapper = styled.div`
  background-color: ${props => (props.background ? props.background : 'rgba(0, 70, 101, 0.08)')};
  padding: 3rem 1rem;

  @media (min-width: 576px) {
    padding: 4% 6%;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Title = styled.h2`
  color: #1a1a1a;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;

  @media (min-width: 768px) {
    margin-bottom: 2rem;
    font-size: 1.875rem;
    text-align: left;
  }
`;

export default function ContentSection(props) {
  const {
    id,
    background,
    title,
    imageUrl,
    imagePosition,
    body,
    carouselVariant,
    carouselItems,
    carouselTitles,
  } = props;

  const optimizedImage = imageUrl ? `${imageUrl}&w=600&h=400` : null;

  return (
    <SectionWrapper background={background}>
      <Section id={id}>
        {imagePosition === 'left' && (
          <img
            alt={title}
            src={id !== 'dumpstations' ? optimizedImage : imageUrl}
            className="content_image left"
            loading="lazy"
          />
        )}
        <div>
          {title && <Title>{title}</Title>}
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </div>
        {imagePosition === 'right' && (
          <img alt={title} src={optimizedImage} className="content_image right" loading="lazy" />
        )}
      </Section>
      {carouselVariant &&
        carouselItems &&
        carouselItems.map((items, index) => (
          <ContentCarousel
            key={`${carouselVariant}-carousel-${index + 1}`}
            variant={carouselVariant}
            items={items}
            title={carouselTitles && carouselTitles[index]}
          />
        ))}
    </SectionWrapper>
  );
}
