import React, { useRef } from 'react';
import styled from 'styled-components';
import loadable from '@loadable/component';

import media from 'core/styles/mediaQuery';

import { buildCarouselCards } from 'content/buildContentCarouselCards';

const DivCarousel = loadable.lib(() =>
  import(/* webpackChunkName: 'NationalPark', webpackPrefetch: true  */ 'components/DivCarouselForwardRef')
);

const Container = styled.div`
  margin-top: 2rem;
  width: 100%;
  position: relative;

  .carousel-cell {
    width: 345px !important;
  }

  .flickity-button {
    background: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid white;
    border-radius: 50%;
    box-shadow: 0px 6px 5px 0px rgba(224,224,224,1);
  }

  .flickity-prev-next-button {
    width: 2.5rem;
    height: 2.5rem;

    &.next {
      right: 2rem;
    }
    &.previous {
      left: 2rem;
    }

    ${media.belowMedium`
      display: none;
    `}
  }

  /* icon color */
  .flickity-button-icon {
    fill: #0d5d67;
  }

  /* disabled button */
  .flickity-button:disabled {
    background-color: transparent !important;

    .flickity-button-icon {
      fill: rgba(13, 93, 103, 0.12);
    }
  }

  /* position dots in carousel */
  .flickity-page-dots {
    bottom: 0px;
  }

  /* white circles */
  .flickity-page-dots .dot {
    width: 0.75rem;
    height: 0.75rem;
    opacity: 1;
    background: #c4c4c4;

    ${media.belowMedium`
      width: 0.5rem;
      height: 0.5rem;
    `}
  }

  /* fill-in selected dot */
  .flickity-page-dots .dot.is-selected {
    background: #172d49;;
  }
`

const Title = styled.h4`
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin: 0;
`

export default function ContentCarousel({title, variant, items, prevNextButtons}) {
  const flickityRef = useRef(null);

  return items?.length ? (
    <Container>
      <div className="row justify-content-md-center no-gutters">
        {title && <Title>{title}</Title>}
        <DivCarousel>
          {({ default: Carousel }) => (
            <Carousel
              ref={flickityRef}
              options={{
                pageDots: true,
                prevNextButtons: prevNextButtons && true,
                cellAlign: 'left',
                percentPosition: false
              }}
            >
              {buildCarouselCards(variant, items)}
            </Carousel>
          )}
        </DivCarousel>
      </div>
    </Container>
  ) : null;
}
