import React from 'react';
import styled, { css } from 'styled-components';

import { Flex } from 'core/ui';

const linkCSS = css`
  color: ${({ theme }) => theme.colors.bodyColor};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.linkColor};
  }
`;

const Headline = styled.h3`
  font-size: 2rem !important;
  font-weight: bold !important;
  margin-top: 4rem !important;
  margin-bottom: 1rem !important;
  line-height: 1.2 !important;
`;

const Link = styled.a`
  display: flex;
  width: 100%;
  padding: 0.5rem 0;
  ${linkCSS}

  ${({ theme }) => theme.medium`
    width: calc(20% - ${(4/5).toFixed(3)}rem);
  `}
`;

const Footer = styled.div`
  margin: 2rem 0;
  font-size: 1.3rem;
  font-weight: bold;

  ${({ theme }) => theme.medium`
    text-align: right;
    margin: 0 0 2rem 0;
  `}
`;

const FooterLink = styled.a`
  display: inline-block;
  padding: 1rem 0;
  color: ${({ theme }) => theme.colors.linkColor};
  ${linkCSS}
`;

const RegionalNearbyCampgrounds = ({ items, headline, description, linkCopy, linkHref }) => {
  if (!items || !items.length) return null;

  return (
    <>
      <Headline>{headline}</Headline>
      <div dangerouslySetInnerHTML={{ __html: description }} />
      <Flex justifyContent="space-between" flexWrap="wrap">
        {items.map(({ slug, name }) => (
          <Link
            key={slug}
            href={`/campgrounds/${slug}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {name}
          </Link>
        ))}
      </Flex>
      <Footer>
        <FooterLink href={linkHref} target="_blank" rel="noopener noreferrer">
          {linkCopy}
        </FooterLink>
      </Footer>
    </>
  );
};

export default RegionalNearbyCampgrounds;
