import React from 'react';
import styled from 'styled-components';

const OrderedList = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: ${(props) => props.padding || '20px 20px 20px 15px'};
  display: flex;
  flex-wrap: wrap;
  text-align: left;

  a {
    text-decoration: none;
    color: #0a2239;
  }
  
  a:hover {
    color: #377ac9;
  }
`;

const GreaterThanSign = styled.span`
  margin-right: 9px;
  margin-left: 9px;
`;

function Breadcrumbs(props) {
  const { linkPaths, currentPage, padding, defaultRootPath = { 'Home' : '/'} } = props;
  const defaultLinkText = Object.keys(defaultRootPath)[0];
  const defaultLinkPath = defaultRootPath[defaultLinkText];

  return (
    <div>
      <OrderedList padding={padding}>
        <li>
          <a href={defaultLinkPath} data-event-label="Breadcrumbs">{defaultLinkText}</a>
        </li>
        { linkPaths && Object.keys(linkPaths).map((k) => {
          return (
            <li key={k}>
              <GreaterThanSign className='gt'><svg fill="none" height="10" viewBox="0 0 10 18" width="6" xmlns="http://www.w3.org/2000/svg"><path d="m9.64753 8.1525c.46877.46875.46877 1.23 0 1.69875l-7.2 7.20005c-.46875.4687-1.23.4687-1.698751 0-.46875-.4688-.46875-1.23 0-1.6988l6.352501-6.3525-6.348751-6.3525c-.46875-.46875-.46875-1.23 0-1.698747.468751-.46875 1.230001-.46875 1.698751 0l7.2 7.199997z" fill="#BFBFBA" /></svg></GreaterThanSign>
              <a href={linkPaths[k]} data-event-label="Breadcrumbs">{k}</a>
            </li>
          )
        }) }
        <li>
          <GreaterThanSign className='gt'><svg fill="none" height="10" viewBox="0 0 10 18" width="6" xmlns="http://www.w3.org/2000/svg"><path d="m9.64753 8.1525c.46877.46875.46877 1.23 0 1.69875l-7.2 7.20005c-.46875.4687-1.23.4687-1.698751 0-.46875-.4688-.46875-1.23 0-1.6988l6.352501-6.3525-6.348751-6.3525c-.46875-.46875-.46875-1.23 0-1.698747.468751-.46875 1.230001-.46875 1.698751 0l7.2 7.199997z" fill="#BFBFBA" /></svg></GreaterThanSign>
          <span className='current'>{currentPage}</span>
        </li>
      </OrderedList>
    </div>
  )
}

export default Breadcrumbs;