import React from 'react';
import styled, { css } from 'styled-components';

const sharedCardStyles = css`
  margin: 4rem 0;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
`;

const CardContainer = styled.div`
  ${sharedCardStyles}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 294px;
  height: 378px;
  flex-grow: 0;
  background-color: #fff;

  h5 {
    margin: 0;
    font-weight: bold;
    font-size: 1.5rem;
  }
`;
const Distance = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  padding: 8px 16.6px;
  border-radius: 10px;
  background-color: rgba(0, 70, 101, 0.08);
  height: 52px;
  width: 60%;

  svg {
    width: 20px;
    margin-right: 1rem;
  }
`;

const ActivityCardContainer = styled.div`
  ${sharedCardStyles}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 237px;
  height: 130px;
  flex-grow: 0;
  background-color: #fff;

  h5 {
    margin: 0;
    font-weight: bold;
    font-size: 1rem;
  }

  p {
    font-size: 0.75rem;
  }
`;
const CampgroundCardContainer = styled.div`
  ${sharedCardStyles}
  width: 332px;
  height: 450px;
  background-color: rgba(255, 255, 255, 0.86);
  overflow: hidden;

  h5 {
    margin: 0;
  }
  img {
    height: 40%;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0;
  }

  div {
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: 0.5rem;
  }
`;

export const DumpstationCardContainer = styled.div`
  ${sharedCardStyles}
  width: 332px;
  height: 388px;
  overflow: hidden;

  img {
    height: 50%;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    margin-bottom: 1rem;
  }
`;

const MakeModelsCardContainer = styled.div`
  margin: 4rem 0;
  height: 197px;
  width: 188px;

  img {
    height: 120px;
    width: 100%
    object-fit: fill;
  }
`;

const buildTrailCards = ({ name, description, distance }) => (
  <div key={`national-parks-trail-${name}}`}>
    <CardContainer>
      <div>
        <h5>{name}</h5>
        <Distance>
          <svg fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            {' '}
            <clipPath id="a">
              {' '}
              <path d="m0 0h20v20h-20z" />{' '}
            </clipPath>{' '}
            <g clipPath="url(#a)">
              {' '}
              <path
                d="m16.3938 0c1.7502 0 3.2062 1.37432 3.2062 3.2 0 .44576-.0995 1.02452-.25 1.43124-.8581 1.84442-1.7247 3.69257-2.5875 5.53746-.064.1371-.2113.2313-.3625.2313h-5.2c-1.0041 0-1.79999.7959-1.79999 1.8s.79589 1.8 1.79999 1.8h2.6c1.6551 0 3 1.3449 3 3s-1.3449 3-3 3h-9.80001c-.15124 0-.29854-.0942-.3625-.2312-.97368-2.0775-1.99116-4.1401-2.956256-6.2188-.1643-.4372-.28124-1.0566-.28124-1.55 0-2.0313 1.630696-3.6 3.606236-3.6 1.97558 0 3.59376 1.56988 3.59376 3.6 0 .4937-.1182 1.1139-.28124 1.55-.89112 1.8824-1.79124 3.7668-2.68752 5.65h9.16877c1.2257 0 2.2-.9743 2.2-2.2s-.9743-2.2-2.2-2.2h-2.6c-1.43347 0-2.59999-1.1665-2.59999-2.6s1.16652-2.6 2.59999-2.6h4.5688c-.7708-1.65729-1.5614-3.30872-2.3188-4.96876-.1493-.4056-.25-.98514-.25-1.43124 0-1.82454 1.4435-3.2 3.1938-3.2zm0 .8c-1.3429 0-2.3938.98734-2.3938 2.4 0 .29578.109.8993.2 1.15.7287 1.56734 1.466 3.13396 2.2 4.7.7166-1.57386 1.4586-3.09574 2.2-4.7.0912-.24982.2-.85356.2-1.15 0-1.41152-1.0635-2.4-2.4062-2.4zm.0062 1c.7684 0 1.4.63154 1.4 1.4s-.6316 1.4-1.4 1.4c-.7685 0-1.4-.63154-1.4-1.4s.6315-1.4 1.4-1.4zm0 .8c-.3361 0-.6.2639-.6.6s.2639.6.6.6c.336 0 .6-.26392.6-.6 0-.3361-.264-.6-.6-.6zm-12.39381 6.6c-1.5594 0-2.80624 1.1895-2.80624 2.8 0 .3417.11954.9623.23124 1.2625.85166 1.8007 1.71182 3.6006 2.56876 5.4.8393-1.8075 1.73666-3.6297 2.56876-5.4.11148-.3011.23128-.9215.23128-1.2625 0-1.6117-1.23432-2.8-2.79376-2.8zm-.0062 1c.98936 0 1.8.8106 1.8 1.8s-.81064 1.8-1.8 1.8c-.98938 0-1.8-.8106-1.8-1.8s.81062-1.8 1.8-1.8zm0 .8c-.55702 0-1 .443-1 1s.44298 1 1 1c.557 0 1-.443 1-1s-.443-1-1-1z"
                fill="#004665"
              />{' '}
            </g>{' '}
          </svg>
          {distance}
        </Distance>
        <p>{description}</p>
      </div>
    </CardContainer>
  </div>
);

const buildActivityCards = ({ name, type, url }) => (
  <div key={`national-parks-activity-${name}`}>
    <ActivityCardContainer>
      <h5>{name}</h5>
      <p>{type}</p>
      {url && (
        <a href={url} rel="noopener noreferrer nofollow" target="_blank">
          Website
        </a>
      )}
    </ActivityCardContainer>
  </div>
);

const buildCampgroundCards = (item, index) => {
  // campgrounds sometimes duplicate, so we need to make key unique using index
  const { name, address, phone, image, description, url } = item;

  const optimizedImage = image ? `${image}&fit=fill&w=300&h=167` : null;

  return (
    <div key={`national-parks-campground-${phone}-${index}`}>
      <CampgroundCardContainer>
        <img src={optimizedImage} loading="lazy" alt={`Map for ${name}`} />
        <h5>
          <a href={url} rel="noopener noreferrer nofollow" target="_blank">
            {name}
          </a>
        </h5>
        <p>{address}</p>
        <p>{phone}</p>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </CampgroundCardContainer>
    </div>
  );
};

const buildDumpstationCards = ({ name, url, address, phone, image, hours }) => {
  const optimizedImage = image ? `${image}&fit=fill&w=300&h=178` : null;

  return (
    <div key={`national-parks-dumpstation-${name}`}>
      <DumpstationCardContainer>
        <img src={optimizedImage} loading="lazy" alt={`Map for ${name}`} />
        <h5>
          {url ? (
            <a href={url} rel="noopener noreferrer nofollow" target="_blank">
              {name}
            </a>
          ) : (
            name
          )}
        </h5>
        <p>{address}</p>
        <p>{phone}</p>
        <p>{hours}</p>
      </DumpstationCardContainer>
    </div>
  );
};

const buildmakeModelsCards = item => (
  <div>
    <MakeModelsCardContainer>
      <img src={item} loading="lazy" alt={`Manufacturer Card ${item.name}`} />
      <p>Name</p>
      <p># of Models</p>
    </MakeModelsCardContainer>
  </div>
);

export function buildCarouselCards(variant, items) {
  switch (variant) {
    case 'trail':
      return items.map(buildTrailCards);
    case 'activity':
      return items.map(buildActivityCards);
    case 'campground':
      return items.map((item, index) => buildCampgroundCards(item, index));
    case 'dumpstation':
      return items.map(buildDumpstationCards);
    case 'makeModels':
      return items.map(buildmakeModelsCards);
    default:
      return null;
  }
}
